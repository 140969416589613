import axios from "axios"
import qs from "qs"
import storage from "./_localStorage"


const baseball = {}


baseball.host = process.env.NODE_ENV === 'production' ? "" : "http://localhost:8301"

//203
// baseball.host = process.env.NODE_ENV === 'production' ? "http://test.naumed.co.kr" : "http://192.168.10.203:8301"


baseball.getConfig = (method, uri, data) => {
    let url = `${baseball.host}${uri}`
  
    // debug
  //   baseball.debug && console.log("baseball.getConfig", { method, url, data })
  
    const config = {
      method: method.toUpperCase(),
      url,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Requested-With, X-Megic-RunEnv, Accept, Authorization, version",
        "Access-Control-Allow-Credentials": "true",
        // Accept: "application/json",
        // "Accept-Language": baseball.lang,
      },
    }
    const accessToken = storage.getItem("accessToken")
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
    }
  
    if (data) {
      if (config.method.toUpperCase() === "GET") {
        url = url + (url.indexOf("?") > 0 ? "&" : "?") + qs.stringify(data)
        config.url = url
      } else {
        config.data = data
      }
    }
  
    return config
}

baseball.request = (param) => {
    const config = baseball.getConfig(param.method, param.uri, param.data)
    const promise = axios(config)
    return new Promise((resolve, reject) => {
      promise
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  
  baseball.requestAll = (...params) => {
    const configList = []
    params.forEach((param) => {
      const config = baseball.getConfig(param.method, param.uri, param.data)
      configList.push(axios(config))
    })
    const promise = axios.all(configList)
    return new Promise((resolve, reject) => {
      promise
        .then(
          axios.spread((...responses) => {
            resolve(responses)
          })
        )
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }
  
  //baseball 에서 지정한 설정을 따르지 않고 비동기 통신을 할때 쓰임
  baseball.requestAxios = (config) => {
    // console.log(config)
    const promise = axios(config)
    return new Promise((resolve, reject) => {
      promise
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          // console.error(error)
          reject(error)
        })
    })
  }
  
  // {host, lang, debug}
  const _useBaseball = (props) => {
    if (props && !props.host) {
      //console.error("host can not be null !")
      //return null
    }
  
    baseball.host = props && props.host ? props.host : baseball.host
    baseball.lang = props && props.lang ? props.lang : "ko"
    baseball.debug = props && !!props.debug
  
    return baseball
  }
  
  const language = "ko"
  
  export default _useBaseball({ lang: language, debug: true })
  
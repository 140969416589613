import React, { useEffect, useState } from "react"
import { APIs, auth, baseball, _localStorage } from "../libs"
import useRouter from "../hooks/useRouterHook"
import _ from "lodash"
import qs from "qs"

const SiteContext = React.createContext({})
const SiteContextProvider = ({ children }) => {
  const { navigate } = useRouter()

  //사이트 info 설정
  const [siteInfo, setSiteInfo] = useState([])

  //회원 사이트 설정
  const [authProfileSetting, setAuthProfileSetting] = useState([])

  //인증 타입
  const [signInType, setSignInType] = useState("")

  //로그인 체크
  const [isLogin, setIsLogin] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  //Main 게시판 정보 (News & Notice)
  const [mainBoardInfo, setMainBoardInfo] = useState({})
  //Main 게시글 권한 (News & Notice)
  const [mainBoardPermissions, setnMainBoardPermissions] = useState({ access: false, read: false, write: false })
  //Main 게시글 목록 (News & Notice)
  const [newsBoardItemList, setNewsBoardItemList] = useState([])

  useEffect(() => {
    if (!auth.isLogin()) {
      auth.logout()
    }
    loadSiteInfo()
    profileSetting()
  }, [])

  //Site info 설정
  const loadSiteInfo = async () => {
    const api = APIs.site.setting_info()

    await baseball
      .request(api)
      .then((res) => {
        if (res.data.code === "SUCCESS") {
          setSiteInfo(res?.data?.data)
        }
      })
      .catch((err) => {})
  }

  //Site authProfileSetting 설정 및 profile initData 설정
  const profileSetting = () => {
    const api = APIs.site.setting_auth()

    baseball
      .request(api)
      .then((res) => {
        if (res.data.code === "SUCCESS") {
          switch (res.data.data.authType) {
            case "IdAndPassword":
              setSignInType("Id")
              break
            case "EmailAndPassword":
              setSignInType("Email")
              break
            case "MobileAndPassword":
              setSignInType("Mobile")
              break
          }

          if (res.data.data.userProfiles && res.data.data.userProfiles.length !== 0) {
            setAuthProfileSetting(res.data.data.userProfiles)
            _localStorage.setItem("guest", { role: { roleCode: res.data.data.roleCodeForGuest } })
            // _.forEach(res.data.data.userProfiles, (jsonData, index) => {
            //     console.log(jsonData)
            //     console.log(index)
            //     _.forEach(jsonData, (data, key) => {
            //         console.log(data)
            //         console.log(key)
            //     })
            // })
          }
        }
      })
      .catch((err) => {})
  }

  //게시판 정보 가져오기
  const loadMainBoardInfo = async (code) => {
    const api = APIs.board.info(code)

    await baseball
      .request(api)
      .then(async (res) => {
        if (res.data.code === "SUCCESS") {
          setMainBoardInfo(res.data.data)

          const roleType = await auth.getLoginUser().role?.roleCode

          if (roleType && res.data.data.permissions[roleType]) {
            setnMainBoardPermissions(res.data.data.permissions[roleType])
          }
        }
      })
      .catch((err) => {})
  }

  //게시글 목록 가져오기
  const loadMainBoardItemList = async (code) => {
    let param = {
      boardCode: code,
      sort: "createdDate,DESC",
      page: 1,
      size: 1,
      showItemToMain: true,
    }

    const api = APIs.board.get_list(param)

    await baseball
      .request(api)
      .then((res) => {
        if (res.data.code === "SUCCESS") {
          setNewsBoardItemList(res.data.data.content)
        }
      })
      .catch((err) => {})
  }

  //로그인
  const login = async (data) => {
    const api = APIs.user.auth_check(data)

    await baseball
      .request(api)
      .then(async (res) => {
        if (res.data.code === "SUCCESS") {
          await auth.login(res.data.data.accessToken, res.data.data.refreshToken, res.data.data.user, data)
          navigate("/")
        }
      })
      .catch((err) => {
        alert("아이디, 비밀번호가 일치하지 않습니다. 다시 입력해주세요.")
      })
  }

  //로그아웃
  const logout = async () => {
    await auth.logout()
    navigate("/account/login")
  }

  //파일 uri return
  const getFileAccessUri = (fileAccessToken, isAttachment, isThumbnail, thumbnailOption) => {
    const api = APIs.file.download(fileAccessToken)
    let uri = baseball.host + api.uri

    if (isAttachment && !isThumbnail) {
      uri += `?download=true`
    } else if (isThumbnail && !isAttachment) {
      if (thumbnailOption && (Object.prototype.toString.call(thumbnailOption) === "[object JSON]" || Object.prototype.toString.call(thumbnailOption) === "[object Object]")) {
        uri += `?thumbnail=true&${qs.stringify(thumbnailOption)}`
      } else if (thumbnailOption) {
        uri += `?thumbnail=true&&${thumbnailOption}`
      } else {
        uri += `?thumbnail=true`
      }
    }

    return uri
  }

  const value = {
    siteInfo,
    setSiteInfo,
    loadSiteInfo,
    authProfileSetting,
    setAuthProfileSetting,
    profileSetting,
    login,
    logout,
    isLogin,
    setIsLogin,
    isAdmin,
    setIsAdmin,
    mainBoardInfo,
    setMainBoardInfo,
    mainBoardPermissions,
    setnMainBoardPermissions,
    newsBoardItemList,
    setNewsBoardItemList,
    loadMainBoardInfo,
    loadMainBoardItemList,
    getFileAccessUri,
  }

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

export const useSiteContext = () => React.useContext(SiteContext)
export default SiteContextProvider

import SiteContextProvider from './Contexts/SiteContext';
import LayoutContextProvider from './Contexts/LayoutContext';
import { Suspense } from 'react';
import { useRoutes } from 'react-router';
import Routes from './Routes';

const Contents = () => {

  //route
  const routing = useRoutes(Routes)

  return(
    <Suspense fallback={null}>
      {routing}
    </Suspense>
  )
}

function App() {
  return (
    <SiteContextProvider>
      <LayoutContextProvider>
        <Contents/>
      </LayoutContextProvider>
    </SiteContextProvider>
  );
}

export default App;

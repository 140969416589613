import jwt_decode from "jwt-decode"
import dayjs from "dayjs"
import _localStorage from "./_localStorage"
import { APIs, baseball } from "."
import _ from "lodash"

const auth = {}

auth.login = async (accessToken, refreshToken, user, requestData) => {
  await _localStorage.setItem("accessToken", accessToken)
  await _localStorage.setItem("refreshToken", refreshToken)
  await _localStorage.setItem("loginUser", user)

  //아이디 기억하기
  if (requestData && requestData.rememberId !== undefined) {
    if (requestData.rememberId) {
      await _localStorage.setItem("rememberId", requestData.userKeyCode)
    } else {
      await _localStorage.removeItem("rememberId")
    }
  }

}

auth.logout = async () => {
  await _localStorage.removeItem("accessToken")
  await _localStorage.removeItem("refreshToken")
  await _localStorage.removeItem("loginUser")
}

auth.getLoginUser = () => {
  if (auth.isLogin()) return _localStorage.getItem("loginUser")
  else return _localStorage.getItem("guest") || {}
}

auth.getAccessToken = () => {
  let token = _localStorage.getItem("accessToken")

  const check = auth.checkToken(token)
  if (check) return token
  else return null
}

auth.getRefreshToken = () => {
  let token = _localStorage.getItem("refreshToken")
  const check = auth.checkToken(token)
  if (check) return token
  else return null
}

auth.refreshAccessToken = async() =>{
  const accessToken = auth.getAccessToken()
  if(accessToken  ) {
    const expTime= auth.getTokenExpTimestamp(accessToken)
    
    if( expTime && dayjs().add(10, 'm').isAfter(dayjs.unix(expTime))) {
     
      
      const refreshToken = await auth.getRefreshToken()
      if(refreshToken) {
       
        const api = APIs.user.auth_check_token({refreshToken:refreshToken})
        baseball.request({method:  api.method,  uri: api.uri , data:api.data})
          .then((res) => {
            
            if(res.data.code=="SUCCESS")  {
              auth.login(res.data.data.accessToken, res.data.data.refreshToken, auth.getLoginUser() , null )
              // console.log("AccessToekn 갱신완료 (" +dayjs().format("HH:mm:ss") + ")")
            }
             
          })
          .catch((e) => {
            console.info(e)
            if(e.response && e.response.data ) alert(e.response.data.message)
          })
      }
    }
  }
}

auth.checkToken = (token) => {
  
  const tokenData = _parseTokenData(token)
  if (!tokenData || !tokenData.exp) return false
  // console.log({token,tokenData, now:dayjs().format("YYYY-MM-DD HH:mm:ss"), exp : dayjs.unix(tokenData.exp).format("YYYY-MM-DD HH:mm:ss")})

  if (dayjs().isBefore(dayjs.unix(tokenData.exp))) {
    return true
  }

  return false
}

auth.getTokenExpTimestamp = (token) => {
  const tokenData = _parseTokenData(token)
  if (!tokenData || !tokenData.exp) return null
  return tokenData.exp
}

auth.isLogin = () => {
  const token = auth.getAccessToken()

  if (auth.checkToken(token)) return true
  else return false
}

auth.isAdmin = () => {
  
  const loginUser = auth.getLoginUser()

    if (loginUser && !!loginUser.role?.permissions?.length){
      return _.includes(loginUser.role?.permissions, "ADMIN_Access")
    } 
    else return false //관리자가 아니면 여기서는 false.
}

auth.isSystemAdmin = () => {
  const loginUser = auth.getLoginUser()

  if (loginUser && loginUser.userType === "Admin") return true
  else return false //시스템관리자가 아니면 여기서는 false.
}

//기억된 아이디 가져오기
auth.getRememberId = () => {
  return _localStorage.getItem("rememberId")
}

/**
 ******************************
 * @PRIVATE_METHOD
 ******************************
 */

function _parseTokenData(token) {
  let payload = ""
  let tokenData = {}
  if (!token) return null
  try {
    tokenData = jwt_decode(token)
  } catch (error) {
    throw new Error(error)
  }

  return tokenData
}

export default auth

import React from "react";

const LayoutContext = React.createContext({})
const LayoutContextProvider = ({children}) => {
 
    const value = {

    }

    return(
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    )

}

export const useLayoutContext = () => React.useContext(LayoutContext)
export default LayoutContextProvider
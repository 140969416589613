/**
 *  _localStorage
 *   - React에서 이용하는 저장소에 Object를 저장할 수 있도록 한다.
 *   
 */

//const _localStorage = _useLocalStorage()
function isJsonString(str) {
	try {
		const json = JSON.parse(str);
		return (typeof json === 'object');
	} catch (e) {
		return false;
	}
}

const _localStorage =  {}

_localStorage.getItem =  (key) => {
    let value = localStorage.getItem(key) 
     value = isJsonString(value)? JSON.parse(value): value
	
    return value
} 

 _localStorage.setItem = (key, value) => { 
	const val = typeof value === 'object'? JSON.stringify(value): value
    localStorage.setItem(key, val) 
	
} 

 _localStorage.removeItem = (key) => {
    localStorage.removeItem(key)
} 




export default _localStorage
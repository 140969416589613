 
import { useMemo } from "react";
import { useParams, useLocation ,useHistory, useRouteMatch ,useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import qs from "qs";

// Hook
function useRouter() {
    const params = useParams();
    const location = useLocation();
    const history = createBrowserHistory();
    // const history = useHistory();   //There is no useHistory hook in 6.0 over.
   // const match = useRouteMatch();  //There is no useRouteMatch hook in 6.0 over.
     const navigate = useNavigate();
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
      return {
        // For convenience add push(), replace(), pathname at top level
        //push: history.push,
        //replace: history.replace,
        pathname: location.pathname,
        // Merge params and parsed query string into single "query" object
        // so that they can be used interchangeably.
        // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
        query: {
          ...qs.parse(location.search.replace("?","")), // Convert string to object 
          ...params
        },
        // Include match, location, history objects so we have
        // access to extra React Router functionality if needed.
       // match,
        location,
        history,
        navigate
      };
    }, [params,  location ]);
  }
 
  export default useRouter
import React, { lazy } from "react"

//Layout
const SiteLayout = lazy(() => import("../Layouts/SiteLayout"))
const BoardLayout = lazy(() => import("../Layouts/BoardLayout"))

//View
//Site
const Site = lazy(() => import("../Views/Site"))

//Account
const Login = lazy(() => import("../Views/Account/Login"))

//Company
const About = lazy(() => import("../Views/Company/About"))
const Greeting = lazy(() => import("../Views/Company/Greeting"))
const History = lazy(() => import("../Views/Company/History"))
const Mission = lazy(() => import("../Views/Company/Mission"))

//Business
const Business = lazy(() => import("../Views/Business"))
const Consulting = lazy(() => import("../Views/Business/Consulting"))
const DataAnalysis = lazy(() => import("../Views/Business/DataAnalysis"))
const ITService = lazy(() => import("../Views/Business/ITService"))
const MedicalDevice = lazy(() => import("../Views/Business/MedicalDevice"))

//ContactUs
const Contact = lazy(() => import("../Views/Contact"))

//Procarenote
const Procarenote = lazy(() => import("../Views/Procarenote"))
const CRF = lazy(() => import("../Views/Procarenote/Solution/CRF"))
//Board
const CRF_Board_Write = lazy(() => import("../Views/Procarenote/Solution/Board/Write"))
const CRF_Board_View = lazy(() => import("../Views/Procarenote/Solution/Board/View"))

//Support
const News = lazy(() => import("../Views/Support/News"))
const News_1 = lazy(() => import("../Views/Support/News/News_1"))
const News_2 = lazy(() => import("../Views/Support/News/News_2"))
const News_3 = lazy(() => import("../Views/Support/News/News_3"))
const NewsList = lazy(() => import("../Views/Support/News/Board/List"))
const NewsView = lazy(() => import("../Views/Support/News/Board/View"))
const NewsWrite = lazy(() => import("../Views/Support/News/Board/Write"))

const Routes = [
  {
    path: "/",
    element: <SiteLayout />,
    children: [
      { path: "", element: <Site /> },

      //Company
      { path: "Company/About", element: <About /> },
      { path: "Company/Greeting", element: <Greeting /> },
      { path: "Company/History", element: <History /> },
      { path: "Company/Mission", element: <Mission /> },

      //Business
      { path: "Business/Consulting", element: <Consulting /> },
      { path: "Business/DataAnalysis", element: <DataAnalysis /> },
      { path: "Business/ITService", element: <ITService /> },
      { path: "Business/MedicalDevice", element: <MedicalDevice /> },

      //ContactUs
      { path: "Contact/ContactUs", element: <Contact /> },

      //Procarenote
      { path: "Procarenote", element: <Procarenote /> },

      //News
      //   { path: "Support/News", element: <News /> },
      //   { path: "Support/News/1", element: <News_1 /> },
      //   { path: "Support/News/2", element: <News_2 /> },
      //   { path: "Support/News/3", element: <News_3 /> },
    ],
  },
  {
    path: "/",
    element: <BoardLayout />,
    children: [
      //Procarenote
      { path: "Procarenote/Solution/:boardCode/:itemNo", element: <CRF /> },
      // { path: "Procarenote/Solution/:boardCode/View/:itemNo", element: <CRF_Board_View />},
      { path: "Procarenote/Solution/:boardCode/Write/:itemNo", element: <CRF_Board_Write /> },

      //Support
      { path: "Support/:boardCode", element: <NewsList /> },
      { path: "Support/:boardCode/:itemNo", element: <NewsView /> },
      { path: "Support/:boardCode/Write/:itemNo", element: <NewsWrite /> },
    ],
  },
  {
    path: "/Account/Login",
    element: <Login />,
  },
]

export default Routes

import qs from "qs"

const GET = "GET"
const POST = "POST"
const PUT = "PUT"
const PATCH = "PATCH"
const DELETE = "DELETE"
const OPTIONS = "OPTIONS"

const APIs = {
  site: {
    setting_info: () => {
      return { name: `사이트 info 설정 조회`, method: "GET", uri: `/api/site-setting/info` }
    },
    setting_auth: () => {
      return { name: `사이트 회원 설정 조회`, method: "GET", uri: `/api/site-setting/authentication` }
    },
  },

  user: {
    regist: (data) => {
      return { name: `회원 가입`, method: "POST", uri: `/api/user/signup`, data }
    },
    id_check_exists: (data) => {
      return { name: `아이디 중복 확인`, method: "POST", uri: `/api/user-key/check-exists`, data }
    },
    send_check_pincode: (data) => {
      return { name: `이메일/모바일 확인(pincode 발행)`, method: "POST", uri: `/api/user-key/check`, data }
    },
    get_regi_token: (data) => {
      return { name: `이메일/모바일 확인 토큰발행(회원가입)`, method: "POST", uri: `/api/user-key/confirm`, data }
    },

    auth_check: (data) => {
      return { name: `회원인증 (아이디/비밀번호)`, method: "POST", uri: `/api/user/signin`, data }
    },
    auth_check_token: (data) => {
      return { name: `회원인증 (Refresh Token)`, method: "POST", uri: `/api/user/signin-by-refresh-token`, data }
    },

    find_id: (data) => {
      return { name: `아이디 찾기(이름/이메일)`, method: "POST", uri: `/api/user/find-id`, data }
    },
    send_password_pincode: (data) => {
      return { name: `비밀번호 찾기 인증번호 발송`, method: "POST", uri: `/api/user/reset-password-token`, data }
    },
    password_reset: (data) => {
      return { name: `비밀번호 재설정(pincode)`, method: "POST", uri: `/api/user/reset-password`, data }
    },

    get_user_info: (userId) => {
      return { name: `사용자 조회`, method: "GET", uri: `/api/user/${userId}` }
    },
    password_change: (userId, data) => {
      return { name: `비밀번호 변경`, method: "POST", uri: `/api/user/change-password/${userId}`, data }
    },
    retire: (data) => {
      return { name: `회원 탈퇴`, method: "POST", uri: `/api/user/retire`, data }
    },

    info_change: (userId, data) => {
      return { name: `내 정보 변경`, method: "PATCH", uri: `/api/user/${userId}`, data }
    },
    check_key_change: (userId, data) => {
      return { name: `이메일/모바일 변경`, method: "POST", uri: `/api/user/change-user-key/${userId}`, data }
    },
  },

  board: {
    info: (boardCode) => {
      return { name: `게시판 정보 조회`, method: "GET", uri: `/api/board/${boardCode}` }
    },

    get_list: (param) => {
      return { name: `게시글 목록 조회`, method: "GET", uri: `/api/board-item?${qs.stringify(param)}` }
    },
    search_list: (param, data) => {
      return { name: `검색글 목록 조회`, method: "POST", uri: `/api/board-item/search?${qs.stringify(param)}`, data }
    },

    get_view: (data) => {
      return { name: `게시글 정보 조회`, method: "GET", uri: `/api/board-item/${data.boardCode}/${data.itemNo}` }
    },
    delete_view: (data) => {
      return { name: `게시글 삭제`, method: "DELETE", uri: `/api/board-item/${data.boardCode}/${data.itemNo}` }
    },
    write: (boardCode, data) => {
      return { name: `게시글 작성`, method: "PUT", uri: `/api/board-item/${boardCode}`, data }
    },
    modify: (boardCode, itemNo, data) => {
      return { name: `게시글 수정`, method: "PATCH", uri: `/api/board-item/${boardCode}/${itemNo}`, data }
    },

    get_file_upload_url: () => {
      return { name: `파일 업로드 url발급`, method: "GET", uri: `/api/board-item-file/upload-url` }
    },

    editor_images_upload: (data) => {
      return { name: `에디터 이미지 업로드`, method: "PUT", uri: `/api/board-item/images`, data }
    },
  },

  file: {
    download: (fileAccessToken) => {
      return { name: `파일 다운로드`, method: "GET", uri: `/api/file/${fileAccessToken}` }
    },
    get_upload_url: () => {
      return { name: `공용 업로드용 URL 생성`, method: "GET", uri: `/api/file/upload-url` }
    },
  },
}

export default APIs

import baseball from './baseball'

const CONSTANT = {}

//정규식
CONSTANT.REGEX = {}

//S3
CONSTANT.S3 = {}

//사용자아이디 : 4-16 자리 영문,숫자 조합
//CONSTANT.REGEX.USERID = /^[A-Za-z0-9\_]{4,16}$/

//사용자아이디 : 공백 확인
CONSTANT.REGEX.USERID = /[\s]/

//사용자비밀번호 : 8-16 자리 영문/특수문자 포함 조합
// CONSTANT.REGEX.USERPASS = /^.*(?=^.{8,16}$)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/

//사용자비밀번호 : 8-20 자리 영문대소문자/숫자/특수문자 포함 조합
// CONSTANT.REGEX.USERPASS = /^.*(?=^.{8,20}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$/

//사용자비밀번호 : 8-20 자리 영문/숫자/특수문자 포함 조합
// CONSTANT.REGEX.USERPASS = /^.*(?=^.{8,20}$)(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$/

//사용자비밀번호 : 8-16 자리 영문/숫자/특수문자 포함 조합
CONSTANT.REGEX.USERPASS = /^.*(?=^.{8,16}$)(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$/

//사용자 이름 : 띄어쓰기 없이 5글자 미만 입력
CONSTANT.REGEX.USERNAME = /^[가-힣]{1,4}$/

//이메일
CONSTANT.REGEX.EMAIL = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i

//연락처
CONSTANT.REGEX.PHONE = /^\d{2,3}-\d{3,4}-\d{4}$/

//시간(HH:mm)
CONSTANT.REGEX.TIME = /^([01][0-9]|2[0-3]):([0-5][0-9])$/

//시간(HHmm)
CONSTANT.REGEX.NOTCOLONTIME = /^([01][0-9]|2[0-3])([0-5][0-9])$/

//에디터 API Key
CONSTANT.TINY_API_KEY = "rl001sheggk1ke6pw0118aequlyyctm4fk5wnwttpnbhex8a"

//s3 url
CONSTANT.S3.THUMBNAIL = `${baseball.host}/api/file/`

CONSTANT.S3.DOWNLOAD = `${baseball.host}/api/file/`

export default CONSTANT
